import { $, $$, downloadBlob } from './dom-utils'
import pdfBase from '../certificate.pdf'
import { generatePdf } from './pdf-util'

export function prepareInputs () {
  // Laurence
  $('#generate-btn-la-c').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'achats'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Laurence',
      birthday: '17/04/1969',
      placeofbirth: 'Chateauroux',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  $('#generate-btn-la-p').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'sport_animaux'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Laurence',
      birthday: '17/04/1969',
      placeofbirth: 'Chateauroux',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  // Christoph

  $('#generate-btn-ch-c').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'achats'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Johann Christoph',
      birthday: '02/12/1962',
      placeofbirth: 'Landau',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  $('#generate-btn-ch-p').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'sport_animaux'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Johann Christoph',
      birthday: '02/12/1962',
      placeofbirth: 'Landau',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  // JB

  $('#generate-btn-jb-c-e').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'achats'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Jean-Baptiste',
      birthday: '22/04/2001',
      placeofbirth: 'Puyricard',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  $('#generate-btn-jb-p-e').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'sport_animaux'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Jean-Baptiste',
      birthday: '22/04/2001',
      placeofbirth: 'Puyricard',
      address: "310 A chemin du chateau d'eau",
      zipcode: '13510',
      city: 'Eguilles',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  $('#generate-btn-jb-c-m').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'achats'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Jean-Baptiste',
      birthday: '22/04/2001',
      placeofbirth: 'Puyricard',
      address: '9 Rue Sénac de Meilhan',
      zipcode: '13001',
      city: 'Marseille',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })

  $('#generate-btn-jb-p-m').addEventListener('click', async (event) => {
    event.preventDefault()
    const MS_PER_MINUTE = 60000
    const creationInstant = new Date((new Date()) - 15 * MS_PER_MINUTE)
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creaHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    const creationHour = creationInstant
      .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      .replace(':', '-')

    const reasons = 'sport_animaux'
    const profile = {
      lastname: 'Doderlein',
      firstname: 'Jean-Baptiste',
      birthday: '22/04/2001',
      placeofbirth: 'Puyricard',
      address: '9 Rue Sénac de Meilhan',
      zipcode: '13001',
      city: 'Marseille',
      datesortie: creationDate,
      heuresortie: creaHour,
    }
    const pdfBlob = await generatePdf(profile, reasons, pdfBase)

    downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
  })
}

export function prepareForm () {
  prepareInputs()
}
